import * as React from "react"

export default function Input({
  label,
  placeholder,
  type = "text",
  required = false,
  labelClass = "",
  inputClass = "",
  fieldsetClass = "dfc mb-1r",
  noLabel = false,
  name = ""
}) {
  const id = (label ? label : placeholder).toLowerCase().replace(/\./g, '').split(" ").join("_")
  if(!name) name = id
  return (
    <fieldset className={fieldsetClass}>
      {
        noLabel 
          ?
            null
          :
            <label htmlFor={id} className={`${labelClass} c-grey`}>{label}</label>
        }
      <input id={id} name={name} placeholder={placeholder} type={type} required={required} className={inputClass} />
    </fieldset>
  )
}